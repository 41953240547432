var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLogin),expression:"!isLogin"}],staticClass:"iconfont icon-tuichu logout",on:{"click":function($event){_vm.logout();
  _vm.click_play();},"mouseenter":_vm.enter_play}}),_c('div',{staticClass:"box"},[_c('div',{staticClass:"banner"},[( _vm.isLogin )?_c('div',{staticClass:"befLogin flex"},[_c('div',{staticClass:"login"},[_c('img',{staticClass:"loginImg",attrs:{"src":require("../assets/imgs/icons/metamask.png"),"alt":""},on:{"click":function($event){_vm.requestAccount();
            _vm.click_play();},"mouseenter":_vm.enter_play}})]),_c('div',{staticClass:"white"},[_c('div',{staticClass:"userBtn"},[_c('i',{staticClass:"iconfont icon-fullscreen-expand",on:{"click":function($event){_vm.hide();
              _vm.click_play();},"mouseenter":_vm.enter_play}})])])]):_c('div',{staticClass:"username"},[_c('div',{staticClass:"name",on:{"click":function($event){_vm.checkPersonal();
          _vm.click_play();},"mouseenter":_vm.enter_play}},[_c('div',{class:{ xiala: !_vm.isUser, shangla: _vm.isUser }},[_c('i',{directives:[{name:"show",rawName:"v-show",value:( !_vm.isLogin ),expression:" !isLogin "}],staticClass:"iconfont icon-xialajiantouxiao"})]),_c('span',[_vm._v(_vm._s(_vm.user_name))])]),_c('div',{staticClass:"coins"},[_c('img',{attrs:{"src":require("@/assets/imgs/shop/coin.png")}}),_vm._v(" X "+_vm._s(_vm.balance)+" ")]),_c('div',{staticClass:"userBtn"},[_c('i',{staticClass:"iconfont icon-fullscreen-expand",on:{"click":function($event){_vm.hide();
            _vm.click_play();},"mouseenter":_vm.enter_play}})])])]),_c('transition',{attrs:{"enter-active-class":"animate__animated animate__fadeInDown","leave-active-class":"animate__animated animate__fadeOutUp"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:( _vm.isUser ),expression:" isUser "}]},[_c('div',{staticClass:"userInfo"},[_c('ul',{staticClass:"table"},[_c('li',{staticClass:"flex"},[_c('div',{staticClass:"tip"},[_vm._v("NAME")]),_c('div',{staticClass:"content"},[_vm._v(_vm._s(_vm.user_name))]),_c('i',{staticClass:"iconfont icon-xiugai",on:{"click":function($event){_vm.changeName();
                _vm.click_play();},"mouseenter":_vm.enter_play}})]),_c('li',{staticClass:"flex"},[_c('div',{staticClass:"tip nobottom"},[_vm._v("DISCORD")]),_c('div',{staticClass:"content nobottom"},[_vm._v(_vm._s(_vm.discord_name))]),_c('i',{staticClass:"iconfont icon-shuaxin-zhongzhi-07",on:{"click":function($event){_vm.bind();
                _vm.click_play();},"mouseenter":_vm.enter_play}})])])]),_c('div',{staticClass:"costFlow"},[_c('div',{staticClass:"title"},[_vm._v("$CARROT History")]),_c('ul',{staticClass:"table scrolly"},[_c('li',{staticClass:"flex header"},[_c('div',{staticClass:"date"},[_vm._v("DATE")]),_c('div',{staticClass:"carrot"},[_vm._v("$CARROT")]),_c('div',{staticClass:"item"},[_vm._v("DETAILS")])]),_vm._l((_vm.costTable),function(item,i){return _c('li',{key:i,staticClass:"flex"},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(item.created_at))]),_c('div',{staticClass:"carrot"},[_vm._v(_vm._s(item.type == 1 ? "+" : "-")+_vm._s(item.point))]),_c('div',{staticClass:"item"},[_vm._v(" "+_vm._s(item.desc ? item.desc : item.game_key)+" ")])])})],2)])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }