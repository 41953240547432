<template>
  <div>
    <i class="iconfont icon-tuichu logout" @click="logout();
    click_play();
                              " @mouseenter=" enter_play " v-show="!isLogin"></i>
    <div class="box">
      <div class="banner">
        <div class="befLogin flex" v-if=" isLogin ">
          <div class="login">
            <img src="../assets/imgs/icons/metamask.png" alt="" class="loginImg" @click="
              requestAccount();
              click_play();
            " @mouseenter=" enter_play " />
          </div>
          <div class="white">
            <div class="userBtn">
              <i class="iconfont icon-fullscreen-expand" @click="
                hide();
                click_play();
              " @mouseenter=" enter_play "></i>
            </div>
          </div>
        </div>
        <div class="username" v-else>
          <div class="name" @click="
            checkPersonal();
            click_play();
          " @mouseenter=" enter_play ">
            <div :class=" { xiala: !isUser, shangla: isUser } ">
              <i class="iconfont icon-xialajiantouxiao" v-show=" !isLogin "></i>
            </div>
            <span>{{ user_name }}</span>
          </div>
          <div class="coins">
            <img src="@/assets/imgs/shop/coin.png" /> X {{ balance }}
          </div>
          <div class="userBtn">
            <i class="iconfont icon-fullscreen-expand" @click="
              hide();
              click_play();
            " @mouseenter=" enter_play "></i>
          </div>
        </div>
      </div>
      <transition enter-active-class="animate__animated animate__fadeInDown"
        leave-active-class="animate__animated animate__fadeOutUp">
        <div v-show=" isUser ">
          <div class="userInfo">
            <ul class="table">
              <li class="flex">
                <div class="tip">NAME</div>
                <div class="content">{{ user_name }}</div>
                <i class="iconfont icon-xiugai" @click="
                  changeName();
                  click_play();
                " @mouseenter=" enter_play "></i>
              </li>
              <!-- <li class="flex">
                <div class="tip">$CARROT</div>
                <div class="content">{{ balance }}</div>
              </li> -->
              <li class="flex">
                <div class="tip nobottom">DISCORD</div>
                <div class="content nobottom">{{ discord_name }}</div>
                <i class="iconfont icon-shuaxin-zhongzhi-07" @click="
                  bind();
                  click_play();
                " @mouseenter=" enter_play "></i>
              </li>
            </ul>
          </div>
          <div class="costFlow">
            <div class="title">$CARROT History</div>
            <ul class="table scrolly">
              <li class="flex header">
                <div class="date">DATE</div>
                <div class="carrot">$CARROT</div>
                <div class="item">DETAILS</div>
              </li>
              <li class="flex" v-for="(      item, i      ) in       costTable      " :key=" i ">
                <div class="date">{{ item.created_at }}</div>
                <div class="carrot">{{ item.type == 1 ? "+" : "-" }}{{ item.point }}</div>
                <div class="item">
                  {{ item.desc ? item.desc : item.game_key }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </transition>
    </div>
    <!-- <audio ref="audio_enter">
      <source src="@/assets/sound/enter.wav" />
    </audio>
    <audio ref="audio_click">
      <source src="@/assets/sound/clik.wav" />
    </audio> -->
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { identify, Identify } from "@amplitude/analytics-browser";
export default {
  name: "",
  components: {},
  data() {
    return {
      user_name: "",
      discord_name: "",
      isUser: false,
      isLogin: true,
      wallet_address: "",
      balance: "", // 萝卜币量
      costTable: "",
      isGame: false,
      mustlogin: false,
      ishide: 0,
    };
  },
  watch: {},
  computed: {},
  created() {
    // 点击开始游戏
    window.addEventListener("getGameStart", this.unityGame);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("getGameStart", this.unityGame);
    });
    // 分享
    window.addEventListener("weekShare", this.unityShare);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("weekShare", this.unityShare);
    });
  },
  async mounted() {
    this.initialize();
    let uers_code = "";
    if (location.href.indexOf("code") != -1) {
      uers_code =
        this.$route.query.code ??
        location.href.substr(location.href.indexOf("code") + 5).split("&")[0];
    }
    console.log(uers_code);
    let token = "";
    if (uers_code) {
      await axios({
        url: "https://discord.com/api/v10/oauth2/token",
        method: "post",
        data: {
          client_id: "1062959228198854726",
          client_secret: "2a52qlA6_q-so57BugXnC8Av2YiVOPLf",
          grant_type: "authorization_code",
          code: uers_code,
          redirect_uri: `http://${location.host}`,
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then((res) => {
        token = res.data.access_token;
      });
      await this.$api
        .bindThirdPartAccount({ token: token, user_account_type: 11 })
        .then((res) => {
          this.getName();
        });
    }
  },
  methods: {
    // 点击开始游戏判断
    async unityGame(obj) {
      if (obj.detail.isGameStart) {
        this.loginState();
        if (this.mustlogin) {
          // return
          if (obj.detail.isGameStart == 1) {
            this.$router.push("/game?key=zombie_bunny");
          } else if (obj.detail.isGameStart == 2) {
            this.$router.push("/game?key=j_jump");
          } else if (obj.detail.isGameStart == 3) {
            // this.$router.push("/game?key=BunnyWeb");
            this.$router.push("/game?key=cyber_wing");
          } else if(obj.detail.isGameStart == 4) {
            this.$router.push("/game?key=BunnyWeb");
          }
        } else {
          this.$message({
            message: "Please login to your MetaMask account",
            showClose: true,
            type: "warning",
          });
        }
      }
    },
    // 每周分享
    async unityShare(obj) {
      if (obj.detail.isWeekShare) {
        console.log(obj.detail.isWeekShare);
        if (obj.detail.isWeekShare == 1) {
          this.$amplitude_track("TWITTER_CLICK");
          window.open("https://twitter.com/ThreeEarsBunny");
        } else if (obj.detail.isWeekShare == 2) {
          this.$amplitude_track("SHARE_CLICK");
          this.loginState();
          if (!this.mustlogin) {
            this.$message({
              message: "Please login to your MetaMask account",
              showClose: true,
              type: "warning",
            });
          } else {
            setTimeout(() => {
              this.$api
                .weeklyShare({
                  wallet_address: this.wallet_address,
                })
                .then(() => {
                  this.getUserPoint();
                });
            }, 3000);
            // .then((res) => {
            //   if (!res.data) {
            //     this.$message({
            //       message:
            //         "Already shared this week, no more points will be earned",
            //       showClose: true,
            //       type: "warning",
            //     });
            //   }
            // });
          }
          window.open(
            "http://twitter.com/intent/tweet?url=" +
              `https://3eb.io/twitter/tebPlanets.html` +
              "&text=Hey, My fams, come and visit the remarkable planet of Three Ears Bunny, a 3D website and extraordinary art! ",
            "_blank",
            "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=1600, height=860,top=100,left=350"
          );
        } else if (obj.detail.isWeekShare == 3) {
          window.open("https://discord.gg/3eb");
        }
      }
    },
    // 初始化
    initialize() {
      this.getName();
      this.getUserPoint();
    },
    // 登录
    requestAccount() {
      this.eth = window.ethereum;
      if (!this.checkWallet()) {
        return;
      }
      this.requestAccounts()
        .then((result) => {
          if (result) {
            this.address = result[0];
            this.connetMetamask();
          }
        })
        .catch((error) => { });
    },
    //请求钱包账号
    requestAccounts() {
      return this.eth.request({ method: "eth_requestAccounts" });
    },
    async connetMetamask() {
      if (!this.checkWallet() || !this.address) {
        return;
      }
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const nance = await this.$api.nonce({
        address: this.address,
        "blockchain-type": "1",
      });
      this.generateSignature(this.address, nance.data)
        .then((signatureResult) => {
          if (signatureResult) {
            this.metamaskLogin(signatureResult);
          } else {
            this.loading.close();
          }
        })
        .catch((error) => {
          this.loading.close();
        });
    },
    generateSignature(publicAddr, nonce) {
      return this.eth.request({
        jsonrpc: "2.0",
        id: 1,
        method: "personal_sign",
        params: [
          publicAddr,
          "Welcome to TEB!\n" +
          'Click "Sign" allow us to make your account secure and NO password needed!\n\n' +
          "Nonce:\n" +
          nonce,
        ],
      });
    },
    //判断是否安装metamask
    checkWallet() {
      if (!this.eth) {
        this.$message({
          message: "Please install the MetaMask",
          showClose: true,
          type: "warning",
        });
        setTimeout(() => {
          window.open("https://metamask.io/download.html");
        }, 1000);
        return false;
      }
      return true;
    },
    async metamaskLogin(signatureResult) {
      const data = await this.$api.metamaskLogin({
        address: this.address,
        blockchain_type: 1,
        sign: signatureResult,
        sign_message:
          "Welcome to TEB!\n" +
          'Click "Sign" allow us to make your account secure and NO password needed!\n\n' +
          "Nonce:\n",
      });
      if (data.result == 1) {
        window.localStorage.setItem("token", data.data.token);
        window.localStorage.setItem("user_id", data.data.user_id);
        window.localStorage.setItem("JWT", true);
        this.$amplitude_track("LOGIN_SUCCESS");
        this.mustlogin = true;
        this.initialize();
      }
      this.loading.close();
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("user_id");
      localStorage.removeItem("JWT");
      localStorage.removeItem("user_name");
      localStorage.removeItem("discord_id");
      this.isLogin = true;
      this.user_name = "";
      this.$message({
        message: "Logout Successful",
        showClose: true,
        type: "success",
      });
      this.$emit("logout", true);
      this.isUser = false;
      this.mustlogin = false;
      // setTimeout(() => {
      //   location.reload();
      // }, 1000);
    },
    // 查询用户信息
    checkPersonal() {
      this.loginState();
      if (this.mustlogin) {
        this.isUser = !this.isUser;
        if (this.isUser) {
          this.getUserPoint();
        }
      } else {
        this.$message({
          message: "Please login to your MetaMask account",
          showClose: true,
          type: "warning",
        });
      }
    },
    // 获取用户名
    async getName() {
      await this.$api
        .getMe()
        .then((res) => {
          this.user_name = res.first_name ?? "";
          this.wallet_address = res.wallet_address ?? "";
          const user_id = res.user_id ?? "";
          let discord_id;
          if (res.user_account) {
            discord_id = res.user_account[0]?.account_id ?? "";
            this.discord_name = res.user_account[0]?.name ?? "";
          } else {
            discord_id = "";
            this.discord_name = "";
          }
          localStorage.setItem("user_name", this.user_name);
          localStorage.setItem("discord_id", discord_id);
          localStorage.setItem("user_id", user_id);
          this.isLogin = false;
          this.$emit("logout", res.wallet_enabled);

          const identifyObj = new Identify();
          identifyObj.set("wallet_address", this.wallet_address);

          identify(identifyObj);

          if (!discord_id) {
            this.$emit("bind", "discord");
          } else {
            this.$emit("bind", "ok");
          }
        })
        .catch((err) => {
          this.user_name = "";
          this.wallet_address = "";
          const discord_id = "";
          this.$emit("logout", true);
          localStorage.setItem("user_name", this.user_name);
          localStorage.setItem("discord_id", discord_id);
          localStorage.setItem("user_id", "");
        });
    },
    // 修改名字
    changeName() {
      this.$emit("bind", "name");
    },
    //换绑discord
    bind() {
      if (this.mustlogin) {
        window.location.href = `https://discord.com/oauth2/authorize?client_id=1062959228198854726&scope=identify%20guilds.join&response_type=code&state=15773059ghq9183habn&prompt=consen&redirect_uri=http%3A%2F%2F${location.host}`;
      } else {
        this.$message({
          message: "Please login to your MetaMask account",
          showClose: true,
          type: "warning",
        });
      }
    },
    // 获取周流水和总积分
    getUserPoint() {
      this.$api.getUserPointRecord().then((res) => {
        this.balance = res.data.balance;
        if (res.data.point_record_resources) {
          res.data.point_record_resources.forEach((item) => {
            item.created_at = moment(item.created_at).utc().format("Do MMM. hh:mmA");
          });
        }
        this.costTable = res.data.point_record_resources;
      });
    },
    // 隐藏星球信息框 给untiy发送
    hide() {
      this.$emit("hide", this.ishide);
      if (this.ishide == 0) {
        this.ishide = 1;
      } else if (this.ishide == 1) {
        this.ishide = 0;
      }
    },
    //登录判断
    loginState() {
      const user_id = localStorage.getItem("user_id");
      if (user_id) {
        this.mustlogin = true;
      } else {
        this.mustlogin = false;
      }
    },
    // 鼠标移入播放音效
    enter_play() {
      // this.$refs.audio_enter.play();
    },
    // 鼠标点击播放
    click_play() {
      // this.$refs.audio_click.play();
    },
  },
  beforeDestroy() { },
};
</script>

<style lang="less" type="text/less" scoped>
.logout {
  font-size: 1.625rem;
  color: #fff;
  opacity: 0.6;
  position: absolute;
  top: 1.25rem;
  right: 0.625rem;
  cursor: pointer;
  z-index: 888;

  &:hover {
    transform: scale(1.05);
    text-shadow: -1px 0px 1px #f89506, 1px 0px 1px #f89506, 0px -1px 1px #f89506;
  }
}

.box {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  .banner {
    width: 26.25rem;
    height: 3.125rem;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 0 0 1.25rem 1.25rem;
    display: flex;

    .befLogin {
      width: 100%;
      height: 100%;

      .login {
        position: relative;
        width: 20%;
        border-right: 1px rgba(110, 110, 110, 0.7) solid;

        img {
          position: relative;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .loginImg {
          width: 40%;
          cursor: pointer;

          &:hover {
            width: 45%;
          }
        }

        .logoImg {
          width: 50%;
        }
      }

      .white {
        width: 80%;
      }
    }

    .username {
      width: 100%;
      position: relative;

      .name {
        position: absolute;
        top: 50%;
        left: 6%;
        transform: translateY(-50%);
        color: #f89506;
        font-size: 1.25rem;
        font-weight: 900;
        cursor: pointer;

        .xiala {
          display: inline-block;
        }

        .shangla {
          display: inline-block;
          transform: rotate(180deg);
        }

        .icon-xialajiantouxiao {
          font-size: 1.25rem;

          &:hover {
            color: #fff;
          }
        }

        span {
          margin-left: 0.625rem;

          &:hover {
            color: #fff;
          }
        }
      }

      .coins {
        position: absolute;
        top: 50%;
        right: 20%;
        transform: translateY(-50%);
        color: #fff;
        font-size: 1.25rem;
        font-weight: 900;

        img {
          width: 1.5625rem;
          height: 1.5625rem;
          vertical-align: bottom;
        }
      }
    }

    .userBtn {
      position: absolute;
      top: 50%;
      right: 5%;
      transform: translateY(-50%);
      text-align: right;
      cursor: pointer;

      i {
        color: #83878b;
        font-size: 28px;
      }

      &:hover {
        transform: translateY(-50%) scale(1.05);
        text-shadow: -1px 0px 1px #f89506, 1px 0px 1px #f89506, 0px -1px 1px #f89506;
      }
    }
  }

  .userInfo {
    width: 23.75rem;
    margin: 0 auto;

    .tip {
      box-sizing: border-box;
      width: 30%;
      border-right: rgba(51, 51, 51, 0.8) 1px solid;
      border-bottom: rgba(51, 51, 51, 0.8) 1px solid;
      overflow: hidden;
      text-align: center;
      padding-right: 5px;
      font-weight: bold;
    }

    .content {
      font-size: 1rem;
      width: 70%;
      border-bottom: rgba(51, 51, 51, 0.8) 1px solid;
      overflow: hidden;
      text-align: left;
      text-indent: 10px;
    }

    .nobottom {
      border-bottom: none;
    }

    i {
      position: absolute;
      font-size: 1.375rem;
      right: 15px;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
        text-shadow: -2px 0px 2px #fff, 2px 0px 2px #fff, 0px -2px 2px #fff;
      }
    }
  }

  .animate__animated.animate__fadeInDown,
  .animate__animated.animate__fadeOutUp {
    --animate-duration: 0.4s;
  }

  .table {
    position: relative;
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: rgba(70, 70, 70, 0.6) 3px solid;
    min-height: 1.875rem;
    line-height: 1.875rem;
    font-size: 1rem;

    li {
      background-color: #f89506;
      opacity: 0.9;
    }
  }

  .costFlow {
    width: 23.75rem;
    margin: 5px auto;

    .title {
      height: 1.875rem;
      line-height: 1.875rem;
      text-align: center;
      font-size: 1.25rem;
      font-weight: 600;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.75);
      border: rgba(70, 70, 70, 0.6) 3px solid;
      border-bottom: none;
    }

    .scrolly {
      max-height: 7.5rem;
      overflow-y: auto;
    }

    ::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.6);
    }

    ::-webkit-scrollbar-track-piece {
      background-color: rgba(0, 0, 0, 0.75);
    }

    .table {
      text-align: center;

      li:last-child div {
        border-bottom: none;
      }

      .header {
        font-weight: 700;
      }

      .date {
        width: 45%;
        border-bottom: rgba(51, 51, 51, 0.8) 1px solid;
        border-right: rgba(51, 51, 51, 0.8) 1px solid;
        overflow: hidden;
      }

      .carrot {
        width: 22%;
        border-bottom: rgba(51, 51, 51, 0.8) 1px solid;
        border-right: rgba(51, 51, 51, 0.8) 1px solid;
        overflow: hidden;
      }

      .item {
        width: 33%;
        border-bottom: rgba(51, 51, 51, 0.8) 1px solid;
        overflow: hidden;
      }
    }
  }
}

.flex {
  display: flex;
}

.flex_col {
  flex-direction: column;
}

.just_bet {
  justify-content: space-between;
}

.align_cen {
  align-items: center;
}
</style>
