<template>
  <div class="main" v-loading="loading" element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.3)">
    <div class="content">
      <user class="user" @bind="userBind" @hide="ishide" @logout="islog" v-show="isBox">
      </user>
      <iframe ref="iframes" src="/webGL/TEBPlanets/index.html" width="100%" height="100%" scrolling="no"
        frameborder="0"></iframe>
    </div>

    <div class="overlay" v-if="isUser && isBox">
      <div class="bind">
        <div class="nameInp flex align_cen" v-if="bindName">
          <i class="iconfont icon-guanbi2"
            @click="isUser = !isUser;
            click_play();
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          "
            @mouseenter=" enter_play "></i>
          <div class="tip">NAME</div>
          <el-input v-model=" user_name " maxlength="15"></el-input>
          <div class="OKbtn" @click="
            changeName();
            click_play();
          " @mouseenter=" enter_play ">
            OK
          </div>
        </div>
        <div class="connet_discord flex" v-if=" bindDsd ">
          <div class="font">Connect with</div>
          <i class="iconfont icon-discord"></i>
        </div>
        <div class="OKbtn" @click="
          bindUser();
          click_play();
        " @mouseenter=" enter_play " v-if=" bindDsd ">
          OK
        </div>
      </div>
    </div>

    <!-- 商城 -->
    <div class="overlay_store" v-show=" isShop ">
      <div class="store_all">
        <div class="store">
          <div class="my_coin">
            <img class="coin" src="@/assets/imgs/shop/coin.png" /> X {{ coins }}
          </div>
          <div class="goods flex">
            <transition enter-active-class="animate__animated animate__bounceIn"
              v-for="(                                               i, num                                               ) in                                                products                                               "
              :key=" num ">
              <div class="commodity" v-show=" isShop ">
                <div class="number" v-if=" i.name == '1X Spot' ">
                  {{ i.global_max_purchase_quantity - i.global_purchased_quantity }}/{{
                  i.global_max_purchase_quantity
                  }}
                </div>
                <img class="com_img" :class=" i.enabled && i.buyable ? 'saleing' : '' " :src=" product_img[i.name] "
                  @click="
                    shopComm(i.id);
                    click_play();
                  " @mouseenter=" enter_play " />
                <div class="info">
                  <div class="name">
                    {{ i.name
                    }}<span class="num" v-if=" i.max_purchase_quantity > 1 ">({{ i.max_purchase_quantity -
                      i.purchased_quantity }}/{{
                      i.max_purchase_quantity
                      }})</span>
                  </div>
                  <div class="prices" style="font-size: 1.125rem">
                    <img class="coin" src="@/assets/imgs/shop/coin.png" /> X
                    {{ i.name == "Mutant Potion" ? "?" : i.gem_price }}
                  </div>
                  <div class="prices notsale" v-if=" i.global_max_purchase_quantity ">
                    {{
                    i.global_max_purchase_quantity - i.global_purchased_quantity == 0
                    ? "Sold Out"
                    : ""
                    }}
                  </div>
                  <div class="prices notsale" v-else>
                    {{ i.enabled ? "" : "Coming soon" }}
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <img src="@/assets/imgs/shop/border.png" class="store_border" />
        <div class="store_gl">
          <video ref="veo" src="@/assets/video/store.mp4" width="100%" height="100%" controls="false" loop="true"
            muted="true" preload="auto"></video>
        </div>
        <img src="@/assets/imgs/shop/close.png" class="store_close" @click="
          closeStore();
          click_play();
        " @mouseenter=" enter_play " />
      </div>
    </div>
    <div class="overlay" v-if=" isBuy ">
      <div class="info">
        <div class="description">
          <p v-for="(                                               des, num                                               ) in                                                description[select_prod.name]                                               "
            :key=" num ">
            {{ des }}
          </p>
        </div>
        <div class="btns">
          <div class="btn" @click="
            click_play();
            buyGoods(select_prod.id);
          " @mouseenter=" enter_play " v-if=" select_prod.enabled && select_prod.buyable ">
            YES
          </div>
          <div class="btn" @click="
            click_play();
            isBuy = false;
          " @mouseenter=" enter_play ">
            CANCEL
          </div>
        </div>
      </div>
    </div>

    <!-- 封禁账号登录 -->
    <ban v-if=" isWallet "> </ban>

    <!-- <audio ref="audio_enter">
      <source src="@/assets/sound/enter.wav" />
    </audio>
    <audio ref="audio_click">
      <source src="@/assets/sound/clik.wav" />
    </audio> -->
  </div>
</template>

<script>
import user from "@/components/user.vue";
import ban from "@/components/ban.vue";
export default {
  name: "",
  components: {
    user,
    ban,
  },
  data() {
    return {
      loading: false,
      isBox: false,
      user_name: "",
      isUser: false,
      bindDsd: false,
      bindName: false,
      isShop: false,
      isWallet: false, //是否为封禁账号
      isBuy: false, //商品购买信息
      coins: 0, //我的萝卜币数量
      products: [], //商品总信息
      select_prod: {},
      description: {
        "1X Spot": [
          "1 x Spot grants you access to mint 2 TEB NFT.",
          "*Your wallet will be Direct to Contract(DTC) if your purchase successfully.",
          "Would you like to buy 1 spot?",
        ],
        "1XSpot (Phase 2)": [
          "1 x Non-Guaranteed Spot (Phase 2) grants you access to mint 2 TEB NFTs (maximum 2 mints for each wallet during phase 2)",
          "Would you like to buy 1 spot?",
        ],
        "OG Role": [
          "OG Benefits:",
          "1. 5 Bunny Tickets for the TEB Lottery House on a weekly basis.",
          "2. One of the criteria for redeeming the mutant potion",
          "*OG can be redeemed in the shop by carrots, win from Lottery House, and handpicked by the team.",
        ],
        "Mutant Potion": [
          "You will get another rare mutant bunny by applying this potion to the common one.",
          "How to redeem the mutant potion? (Limited Supply)",
          "1. OG Role",
          "2. Bunizen Role",
          "3. ???? $CARROT (TBA)",
        ],
        "Bunny Ticket": [
          "You can gain access to the TEB Lottery House by purchasing a Bunny Ticket.",
          "Prize of TEB Lottery House",
          "1. 1/1 TEB NFT",
          "2. FREE TEB NFT",
          "3. 1/2/3 Whitelist Spots",
          "4. OG Role",
          "5. $CARROT(100% winning chance)",
        ],
      },
      product_img: {
        "1X Spot": require("@/assets/imgs/shop/icon_wl.png"),
        "1XSpot (Phase 2)": require("@/assets/imgs/shop/icon_ngwl.png"),
        "OG Role": require("@/assets/imgs/shop/icon_og.png"),
        "Mutant Potion": require("@/assets/imgs/shop/icon_toxi.png"),
        "Bunny Ticket": require("@/assets/imgs/shop/icon_box.png"),
      },
    };
  },
  watch: {
    isBox(newV) {
      if (newV) {
        const user_id = localStorage.getItem("user_id") ?? "";
        const discord_id = localStorage.getItem("discord_id") ?? "";
        if (user_id) {
          if (!discord_id) {
            this.isUser = true;
            this.bindDsd = true;
            this.bindName = false;
          }
        }
      }
    },
  },
  computed: {},
  created() {
    // 成功启动发送
    window.addEventListener("getStart", this.unityLaunch);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("getStart", this.unityLaunch);
    });
    window.addEventListener("openStore", this.unitystore);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("openStore", this.unitystore);
    });
    window.addEventListener("storeStart", this.storeStart);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("storeStart", this.storeStart);
    });
  },
  async mounted() {},
  methods: {
    // 游戏成功启动后显示用户
    unityLaunch(obj) {
      if (obj.detail.isStart) {
        this.$amplitude_track("PAGE_ENTER");
        this.isBox = true;
        if (localStorage.getItem("user_id")) {
          this.$amplitude_track("LOGIN_SUCCESS");
        }
      }
    },
    // 关闭商城
    closeStore() {
      this.$amplitude_track("STORE_LEAVE");
      this.$refs.veo.pause();
      this.isBox = true;
      this.isShop = false;
    },

    //监听商店状态
    unitystore(obj) {
      if (obj.detail.isStore) {
        this.$amplitude_track("STORE_ENTER");
        const user_id = localStorage.getItem("user_id");
        this.isBox = false;
        this.isShop = true;
        this.$refs.veo.play();
        if (user_id) {
          this.loading = true;
          this.query_carr();
          this.$api
            .getProducts()
            .then((res) => {
              this.products = res.data;
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.coins = 0;
          this.$api
            .getNOTloginProducts()
            .then((res) => {
              this.products = res.data;
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      }
    },

    //查询萝卜币
    query_carr() {
      this.loading = true;
      this.$api
        .getUserPointRecord()
        .then((res) => {
          this.loading = false;
          this.coins = res.data.balance;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    // 点击商品
    shopComm(id) {
      this.isBuy = true;
      this.$amplitude_track("PRODUCT_CLICK", { product_id: id });
      this.products.forEach((item) => {
        if (item.id == id) {
          this.select_prod = item;
        }
      });
    },

    //确认购买
    buyGoods(id) {
      this.isBuy = false;
      const user_id = localStorage.getItem("user_id");
      if (user_id) {
        this.loading = true;
        this.$api
          .buyProducts({ product_id: id })
          .then((res) => {
            // 币不够了点击购买
            if (res.error_code == "106118") {
              this.$message({
                message:
                  "You don't have enough $Carrots, please get some more and try again later.",
                showClose: true,
                type: "error",
              });
              this.loading = false;
              return;
            } else if (res.error_code == "106117") {
              // 购买到最大数后又购买
              this.$message({
                message:
                  "You already have the maximum number of item allowed per wallet, so you cannot exchange more.",
                showClose: true,
                type: "error",
              });
              this.loading = false;
              return;
            }
            this.query_carr();
            this.$api
              .getProducts()
              .then((rs) => {
                this.products = rs.data;
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
            this.$amplitude_track("PRODUCT_PAY_SUCCESS", { product_id: id });
            // 购买成功后
            this.$message({
              message: "Your purchase has been successful.",
              showClose: true,
              type: "success",
            });
          })
          .catch((err) => {
            this.loading = false;
            this.$message({
              message: "Purchase Failure, please try again later.",
              showClose: true,
              type: "error",
            });
          });
      } else {
        this.$message({
          message: "Please sign in first.",
          showClose: true,
          type: "error",
        });
      }
    },

    // 判断是否封禁
    islog(msg) {
      console.log("ban", msg);
      if (msg === false) {
        this.isWallet = true;
      } else {
        this.isWallet = false;
      }
    },

    ishide(msg) {
      this.$refs.iframes.contentWindow.send(msg);
    },

    userBind(msg) {
      this.isUser = msg;
      if (msg == "discord") {
        this.isUser = true;
        this.bindDsd = true;
        this.bindName = false;
      } else if (msg == "name") {
        this.isUser = true;
        this.bindDsd = false;
        this.bindName = true;
      } else {
        this.isUser = false;
        this.bindDsd = false;
        this.bindName = false;
      }
      this.user_name = localStorage.getItem("user_name") ?? "";
    },
    // 修改用户名
    changeName() {
      // 用户取名判断
      if (!this.user_name) {
        this.$message({
          message: "Please enter your username",
          showClose: true,
          type: "warning",
        });
        return;
      }
      this.loading = true;
      this.$api
        .profile({ first_name: this.user_name })
        .then((res) => {
          this.loading = false;
          if (res.first_name) {
            this.isUser = false;
            this.isBox = false;
            this.$nextTick(() => {
              this.isBox = true;
            });
          } else if (res.error_code == "105122") {
            this.$message({
              message: "User name can only be modified once in 7 days!",
              showClose: true,
              type: "warning",
            });
            return;
          } else {
            this.$message({
              message:
                "The entered user name does not match the rules, please re-enter it!",
              showClose: true,
              type: "warning",
            });
            return;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    bindUser() {
      const user_id = localStorage.getItem("user_id") ?? "";
      if (user_id) {
        window.location.href = `https://discord.com/oauth2/authorize?client_id=1062959228198854726&scope=identify%20guilds.join&response_type=code&state=15773059ghq9183habn&prompt=consen&redirect_uri=http%3A%2F%2F${location.host}`;
      }
    },
    // 鼠标移入播放音效
    enter_play() {
      // this.$refs.audio_enter.play();
    },
    // 鼠标点击播放
    click_play() {
      // this.$refs.audio_click.play();
    },
  },
  beforeDestroy() { },
};
</script>

<style lang="less" type="text/less" scoped>
@keyframes goods_move {
  0% {
    transform: translateY(0);
  }

  12% {
    transform: translateY(-5px);
  }

  24% {
    transform: translateY(-10px);
  }

  36% {
    transform: translateY(-5px);
  }

  48% {
    transform: translateY(0);
  }

  60% {
    transform: translateY(5px);
  }

  72% {
    transform: translateY(10px);
  }

  84% {
    transform: translateY(5px);
  }

  96% {
    transform: translateY(1.5px);
  }

  100% {
    transform: translateY(0);
  }
}

.main {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  min-width: 1130px;
  z-index: -1600;

  .content {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .overlay_store {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 500;

    .store {
      .my_coin {
        z-index: 200;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -15rem);
        color: #fff;
        font-size: 1.25rem;
        text-align: center;
        text-shadow: 0 1px #000, 1px 0 #000, -1px 0 #000, 0 -1px #000;

        .coin {
          width: 1.5625rem;
          height: 1.5625rem;
          vertical-align: bottom;
        }
      }

      .goods {
        z-index: 200;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-28rem, 4rem);

        .commodity {
          margin-right: 2rem;
          position: relative;
          width: 7.9375rem;
          height: 7.9375rem;

          .number {
            position: absolute;
            top: -1.2rem;
            left: 50%;
            transform: translateX(-50%);
            color: #efd926;
            font-size: 1.25rem;
            font-weight: 900;
            text-shadow: 0 1px #000, 1px 0 #000, -1px 0 #000, 0 -1px #000;
            text-align: center;
            white-space: nowrap;
            margin-bottom: 5px;
          }

          .com_img {
            width: 7.9375rem;
            height: 7.9375rem;
            animation: goods_move 1s infinite linear;
            animation-play-state: paused;
            -webkit-filter: grayscale(1);
            /* Webkit */
            filter: grayscale(1);

            /* W3C */
            &:hover {
              animation-play-state: running;
              cursor: pointer;
            }
          }

          .saleing {
            -webkit-filter: grayscale(0);
            /* Webkit */
            filter: grayscale(0);
            /* W3C */
          }

          .info {
            position: absolute;
            bottom: -4.5rem;
            left: 50%;
            transform: translateX(-50%);

            .name {
              color: #efd926;
              font-size: 1.25rem;
              font-weight: 900;
              text-shadow: 0 1px #000, 1px 0 #000, -1px 0 #000, 0 -1px #000;
              text-align: center;
              white-space: nowrap;
              margin-bottom: 5px;

              .num {
                font-weight: normal;
                color: #fff;
              }
            }

            .prices {
              color: #fff;
              font-size: 1rem;
              text-align: center;
              text-shadow: 0 1px #000, 1px 0 #000, -1px 0 #000, 0 -1px #000;

              .coin {
                width: 1.5625rem;
                height: 1.5625rem;
                vertical-align: bottom;
              }
            }

            .notsale {
              height: 1.375rem;
              white-space: nowrap;
            }
          }
        }
      }
    }

    .store_gl {
      z-index: 99;
      position: absolute;
      width: 64rem;
      height: 36rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      video::-webkit-media-controls {
        display: none !important;
      }

      video:focus {
        outline: -webkit-focus-ring-color auto 0px;
      }
    }

    .store_border {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 65.625rem;
      height: 38.4375rem;
      transform: translate(-50%, -19.7rem);
      z-index: 100;
    }

    .store_close {
      cursor: pointer;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(33.3rem, -19.5rem);
      width: 3.125rem;
      height: 3.125rem;
      z-index: 101;

      &:hover {
        transform: translate(33.3rem, -19.5rem) scale(1.05);
      }
    }
  }

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 999;

    .bind {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 36.25rem;
      height: 13.4375rem;
      background-color: #f89506;
      opacity: 0.9;
      border: rgba(0, 0, 0, 0.8) 4px solid;
      border-radius: 20px;

      .nameInp {
        width: 30rem;
        margin: 3.125rem auto;

        i {
          position: absolute;
          top: 6px;
          right: 6px;
          font-size: 26px;
          cursor: pointer;

          &:hover {
            transform: scale(1.05);
          }
        }

        .tip {
          font-size: 1.5rem;
          font-weight: 600;
          margin-right: 0.9375rem;
        }

        .OKbtn {
          position: absolute;
          bottom: 1.25rem;
          right: 1.25rem;
        }

        &/deep/ .el-input__inner {
          font-size: 1.375rem;
          box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.5);
        }
      }

      .connet_discord {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .font {
          font-size: 2rem;
          font-weight: 600;
          margin-right: 8px;
        }

        i {
          font-size: 2rem;
        }
      }

      .OKbtn {
        position: absolute;
        bottom: 1.25rem;
        right: 1.25rem;
        width: 6.25rem;
        height: 2.8125rem;
        line-height: 2.8125rem;
        text-align: center;
        font-size: 1.875rem;
        font-weight: bold;
        color: #f89506;
        background: #000;
        border-radius: 1.875rem;
        border: rgba(0, 0, 0, 0.8) 3px solid;
        cursor: pointer;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .info {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 420px;
      background-color: #f89506;
      opacity: 0.9;
      border-radius: 15px;
      border: 2px solid #000;

      .description {
        color: #000;
        font-weight: 700;
        font-size: 14px;
        padding: 10px 10px 15px 10px;

        p {
          margin: 0 0 10px 0;
          line-height: 18px;
          // text-align: justify;
        }
      }

      .btns {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 10px;

        .btn {
          width: 100px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #f89506;
          font-size: 16px;
          font-weight: 700;
          background-color: #000;
          border-radius: 15px;
          cursor: pointer;

          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}

.flex {
  display: flex;
}

.align_cen {
  align-items: center;
}
</style>
