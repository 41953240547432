var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"main",attrs:{"element-loading-text":"Loading...","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0.3)"}},[_c('div',{staticClass:"content"},[_c('user',{directives:[{name:"show",rawName:"v-show",value:(_vm.isBox),expression:"isBox"}],staticClass:"user",on:{"bind":_vm.userBind,"hide":_vm.ishide,"logout":_vm.islog}}),_c('iframe',{ref:"iframes",attrs:{"src":"/webGL/TEBPlanets/index.html","width":"100%","height":"100%","scrolling":"no","frameborder":"0"}})],1),(_vm.isUser && _vm.isBox)?_c('div',{staticClass:"overlay"},[_c('div',{staticClass:"bind"},[(_vm.bindName)?_c('div',{staticClass:"nameInp flex align_cen"},[_c('i',{staticClass:"iconfont icon-guanbi2",on:{"click":function($event){_vm.isUser = !_vm.isUser;
          _vm.click_play();},"mouseenter":_vm.enter_play}}),_c('div',{staticClass:"tip"},[_vm._v("NAME")]),_c('el-input',{attrs:{"maxlength":"15"},model:{value:( _vm.user_name ),callback:function ($$v) { _vm.user_name =$$v},expression:" user_name "}}),_c('div',{staticClass:"OKbtn",on:{"click":function($event){_vm.changeName();
          _vm.click_play();},"mouseenter":_vm.enter_play}},[_vm._v(" OK ")])],1):_vm._e(),( _vm.bindDsd )?_c('div',{staticClass:"connet_discord flex"},[_c('div',{staticClass:"font"},[_vm._v("Connect with")]),_c('i',{staticClass:"iconfont icon-discord"})]):_vm._e(),( _vm.bindDsd )?_c('div',{staticClass:"OKbtn",on:{"click":function($event){_vm.bindUser();
        _vm.click_play();},"mouseenter":_vm.enter_play}},[_vm._v(" OK ")]):_vm._e()])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:( _vm.isShop ),expression:" isShop "}],staticClass:"overlay_store"},[_c('div',{staticClass:"store_all"},[_c('div',{staticClass:"store"},[_c('div',{staticClass:"my_coin"},[_c('img',{staticClass:"coin",attrs:{"src":require("@/assets/imgs/shop/coin.png")}}),_vm._v(" X "+_vm._s(_vm.coins)+" ")]),_c('div',{staticClass:"goods flex"},_vm._l((_vm.products),function(i,num){return _c('transition',{key:num,attrs:{"enter-active-class":"animate__animated animate__bounceIn"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:( _vm.isShop ),expression:" isShop "}],staticClass:"commodity"},[( i.name == '1X Spot' )?_c('div',{staticClass:"number"},[_vm._v(" "+_vm._s(i.global_max_purchase_quantity - i.global_purchased_quantity)+"/"+_vm._s(i.global_max_purchase_quantity)+" ")]):_vm._e(),_c('img',{staticClass:"com_img",class:i.enabled && i.buyable ? 'saleing' : '',attrs:{"src":_vm.product_img[i.name]},on:{"click":function($event){_vm.shopComm(i.id);
                  _vm.click_play();},"mouseenter":_vm.enter_play}}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(i.name)),( i.max_purchase_quantity > 1 )?_c('span',{staticClass:"num"},[_vm._v("("+_vm._s(i.max_purchase_quantity - i.purchased_quantity)+"/"+_vm._s(i.max_purchase_quantity)+")")]):_vm._e()]),_c('div',{staticClass:"prices",staticStyle:{"font-size":"1.125rem"}},[_c('img',{staticClass:"coin",attrs:{"src":require("@/assets/imgs/shop/coin.png")}}),_vm._v(" X "+_vm._s(i.name == "Mutant Potion" ? "?" : i.gem_price)+" ")]),( i.global_max_purchase_quantity )?_c('div',{staticClass:"prices notsale"},[_vm._v(" "+_vm._s(i.global_max_purchase_quantity - i.global_purchased_quantity == 0 ? "Sold Out" : "")+" ")]):_c('div',{staticClass:"prices notsale"},[_vm._v(" "+_vm._s(i.enabled ? "" : "Coming soon")+" ")])])])])}),1)]),_c('img',{staticClass:"store_border",attrs:{"src":require("@/assets/imgs/shop/border.png")}}),_c('div',{staticClass:"store_gl"},[_c('video',{ref:"veo",attrs:{"src":require("@/assets/video/store.mp4"),"width":"100%","height":"100%","controls":"false","loop":"true","muted":"true","preload":"auto"},domProps:{"muted":true}})]),_c('img',{staticClass:"store_close",attrs:{"src":require("@/assets/imgs/shop/close.png")},on:{"click":function($event){_vm.closeStore();
        _vm.click_play();},"mouseenter":_vm.enter_play}})])]),( _vm.isBuy )?_c('div',{staticClass:"overlay"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"description"},_vm._l((_vm.description[_vm.select_prod.name]),function(des,num){return _c('p',{key:num},[_vm._v(" "+_vm._s(des)+" ")])}),0),_c('div',{staticClass:"btns"},[( _vm.select_prod.enabled && _vm.select_prod.buyable )?_c('div',{staticClass:"btn",on:{"click":function($event){_vm.click_play();
          _vm.buyGoods(_vm.select_prod.id);},"mouseenter":_vm.enter_play}},[_vm._v(" YES ")]):_vm._e(),_c('div',{staticClass:"btn",on:{"click":function($event){_vm.click_play();
          _vm.isBuy = false;},"mouseenter":_vm.enter_play}},[_vm._v(" CANCEL ")])])])]):_vm._e(),( _vm.isWallet )?_c('ban'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }