<template>
    <!-- 封禁账号登录 -->
  <div class="overlay_waring">
    <div class="tip">
      You have been detected playing the game with cheating and your account has been
      temporarily blocked. If you have any questions, please contact the mod in Discord
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {},
  beforeDestroy() {},
};
</script>

<style lang="less" type="text/less" scoped>
.overlay_waring {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 800;
  .tip {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: red;
    font-size: 26px;
    background-color: #000;
  }
}
</style>
